import Address from "./Address";
export default class Person 
{
    id = null;
    rfc = null;
    curp = null;
    name = null;
    middle_name = null;
    paternal_name = null;
    maternal_name = null;
    email = null;
    phone = null;
    cellphone = null;
    gender = null;
    age = null;
    birthday = null;
    marital_status = null;
    city_of_birth = null;
    state_name_birth = null;
    adress = null;

    constructor(person){
        const keys = Object.keys((person==null)?{}:person);
        this.id = (keys.includes("id"))? person.id: 0;
        this.client_id = (keys.includes("client_id"))? person.client_id: 0;
        this.rfc = (keys.includes("rfc"))? person.rfc: "";
        this.curp = (keys.includes("curp"))? person.curp: "";
        this.name = (keys.includes("name"))? person.name: "";
        this.middle_name = (keys.includes("middle_name"))? person.middle_name: "";
        this.paternal_name = (keys.includes("paternal_name"))? person.paternal_name: "";
        this.maternal_name = (keys.includes("maternal_name"))? person.maternal_name: "";
        this.email = (keys.includes("email"))? person.email: "";
        this.phone = (keys.includes("phone"))? person.phone: "";
        this.cellphone = (keys.includes("cellphone"))? person.cellphone: "";
        this.gender = (keys.includes("gender"))? person.gender: "";
        this.age = (keys.includes("age"))? person.age: "";
        this.birthday = (keys.includes("birthday"))? person.birthday: "";
        this.marital_status = (keys.includes("marital_status")) ? person.marital_status: "";
        this.city_of_birth = (keys.includes("city_of_birth")) ? person.city_of_birth: "";
        this.state_name_birth = (keys.includes("state_name_birth")) ? person.state_name_birth: "";
        this.adress = {}
    }

    // set_adress( { postalCode,municipality,suburb,street,ext_number,int_number,street,city,state,country })
    // {
    //     this.adress = new Address({ postalCode,municipality,suburb,street,ext_number,int_number,street,city,state,country });
    // }

    validate(person){
        return person.name == "" && person.rfc == "" && person.paternal_name == "" && person.maternal_name == "" ? false : true;
    }

    get_full_name(){
        return `${this.name} ${this.middle_name == null ? "" :this.middle_name} ${this.paternal_name} ${this.maternal_name}`;
    }

    validate_rfc_structure(rfc) {
        rfc = rfc.toUpperCase();
        let rfc_pattern =
            "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
            "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
            "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
            "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
            
        const coincidences = rfc.match(rfc_pattern);

        const data = [];
        if (coincidences != null) {
            coincidences.forEach((element) => {
                if (typeof element != "undefined") {
                    data.push(element);
                }
            });
        }
        return coincidences == null
            ? { status: false, coincidences: data }
            : { status: true, coincidences: data };
    }

    get_full_name()
    {
        return [this.name,this.middle_name,this.paternal_name,this.maternal_name].filter(x=>x).join(" ");
    }

    is_empty()
    {
        return this.id == 0  &&
        this.client_id == 0  &&
        this.rfc == ""  &&
        this.curp == ""  &&
        this.name == ""  &&
        this.middle_name == ""  &&
        this.paternal_name == ""  &&
        this.maternal_name == ""  &&
        this.email == ""  &&
        this.phone == ""  &&
        this.cellphone == ""  &&
        this.gender == ""  &&
        this.age == ""  &&
        this.birthday == ""  &&
        this.marital_status == ""  &&
        this.city_of_birth == ""  &&
        this.state_name_birth == "" ? true : false;
    }

    validateCurpStructure(curp) {
        let curp_pattern =
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
        const coincidences = curp.match(curp_pattern);
        return coincidences == null ? false : true;
    }

    validateMatchStrings = (str, matchStr) => {
        str = str.toString().trim().toUpperCase().substr(0, 10);
        matchStr = matchStr.toString().trim().toUpperCase().substr(0, 10);
  
        return str === matchStr;
      }

}