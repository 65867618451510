import api from '@/api/api';
import Person from '@/classes/Person';
export default class Quote {
    
    constructor(quote){
        const keys = Object.keys((quote==null)?{}:quote);
        this.person = new Person(quote),
        this.user_id = (keys.includes("user_id"))? quote.user_id: "";
        this.is_smoker = (keys.includes("is_smoker"))? quote.is_smoker: "";
        this.smoker = (keys.includes("smoker"))? quote.smoker: {};
        this.narcotics = (keys.includes("narcotics"))? quote.narcotics: {};
        this.is_additional_insured = (keys.includes("is_additional_insured"))? quote.is_additional_insured: "";
        this.assured_amount = (keys.includes("assured_amount"))? quote.assured_amount: 0;
        this.alcohol = (keys.includes("alcohol"))? quote.alcohol: [];
        this.coverages = (keys.includes("coverages"))? quote.coverages: [];
        this.diseases = (keys.includes("diseases"))? quote.diseases: [];
        this.occupations = (keys.includes("occupations"))? quote.occupations: [];
        this.sports = (keys.includes("sports"))? quote.sports: [];
        this.hobbies = (keys.includes("hobbies"))? quote.hobbies: [];
        this.activities = (keys.includes("activities"))? quote.activities: [];
        this.surgery = (keys.includes("surgery"))? quote.surgery: [];
        this.weight = (keys.includes("weight"))? quote.weight: 0;
        this.height = (keys.includes("height"))? quote.height: 0;
        this.dependent_life_insurance = (keys.includes("dependent_life_insurance"))? quote.dependent_life_insurance: {}
        this.relationship = (keys.includes("relationship"))? quote.relationship: "";
    }

    createQuote = async(token, params, url) => {
        const config = this.getHeader(token);
        return await api.httpPost(url, config, params);
    }

    getHeader(token){
        return {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };
    }

    get(type){
        return this.getStorage(type);
    }

    save(type, value){
        this.saveStorage(type, value)
    }

    getStorage(type){
        return JSON.parse(localStorage.getItem(`quote-${type}`)) || {};
    }

    saveStorage(type, value){
        return localStorage.setItem(`quote-${type}`, JSON.stringify(value));
    }

    get_dependents()
    {
        const array_dependents = JSON.parse(sessionStorage.getItem("user-information"));
        if(array_dependents.beneficiaries == null) return[];

        return array_dependents.beneficiaries.filter( (dependent) => {
            if(dependent.relationship.toLowerCase() == "hijo" || dependent.relationship.toLowerCase() == "conyuge")
            {
                return dependent
            }
        }); 
    }
   
}